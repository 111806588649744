// Vendors
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

// Types
import { IAction } from "./../types/app";
import {
  INewPermit,
  INewRequest,
  INewVacation,
  OrdsState,
} from "./../types/ords";

// Requests
import {
  CalculateWorkingDays,
  GetAssignations,
  GetCertificates,
  GetContractDetail,
  GetContracts,
  GetDays,
  GetDiscounts,
  GetHolidays,
  GetPayments,
  GetPermissions,
  GetRequests,
  GetSalaryStructureDetail,
  NewPermit,
  NewRequests,
  NewVacation,
} from "api/requests/ORDSRequest";
import dayjs from "dayjs";
import { paramsOrdsRequest } from "types/api";

const PREFIX = "ORDS";

const initialState: OrdsState = {
  loading: false,
  serverErrors: false,
  errorMessage: "",
  success: false,
  contracts: { data: [], count: 0 },
  discounts: { data: [], count: 0 },
  certificates: { workSeniority: [], rent: [], user: {} },
  payments: { data: [], count: 0 },
  assignations: { data: [], count: 0 },
  requests: { data: [], count: 0 },
  permissions: {
    p_disponibles: 0,
    permissionsRequests: [],
    permissionsRegistereds: [],
  },
  contractDetail: {},
  salaryStructureDetail: [],
  calculateWorkingDays: {
    loading: false,
    serverErrors: false,
    errorMessage: "",
    success: false,
  },
  holidays: {
    p_acumulados: 0,
    p_antiguedad: 0,
    p_disponibles: 0,
    p_interrumpidos: 0,
    p_periodo: 0,
    p_progresivo: 0,
    p_tomados_periodo: 0,
    p_transferidos: 0,
    p_zona: 0,
    holidaysRequests: [],
    holidaysRegistereds: [],
  },
};

// Obtener contratos
export const getContracts = createAsyncThunk(
  `${PREFIX}/contracts`,
  async (params: paramsOrdsRequest) => {
    const { error, data } = await GetContracts(params);
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Obtener detalle de contrato
export const getContractDetail = createAsyncThunk(
  `${PREFIX}/contract/detail`,
  async (id: string) => {
    const { error, data } = await GetContractDetail({ id });
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Obtener detalle de estructura salarial
export const getSalaryStructureDetail = createAsyncThunk(
  `${PREFIX}/contract/salary-structure/detail`,
  async (id: string) => {
    const { error, data } = await GetSalaryStructureDetail({ id });
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Obtener descuentos
export const getDiscounts = createAsyncThunk(
  `${PREFIX}/discounts`,
  async (params: paramsOrdsRequest) => {
    const { error, data } = await GetDiscounts(params);
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Obtener certificados
export const getCertificates = createAsyncThunk(
  `${PREFIX}/certificates`,
  async () => {
    const { error, data } = await GetCertificates();
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Obtener remuneraciones
export const getPayments = createAsyncThunk(
  `${PREFIX}/payments`,
  async (params: paramsOrdsRequest) => {
    const { error, data } = await GetPayments(params);
    if (error) {
      throw Error(ordsHandlerErrors(params));
    } else {
      return data;
    }
  }
);

// Obtener asignación
export const getAssignations = createAsyncThunk(
  `${PREFIX}/assignations`,
  async (params: paramsOrdsRequest) => {
    const { error, data } = await GetAssignations(params);
    if (error) {
      throw Error(ordsHandlerErrors(params));
    } else {
      return data;
    }
  }
);

// Obtener permisos
export const getPermissions = createAsyncThunk(
  `${PREFIX}/permissions`,
  async () => {
    const { error, data } = await GetPermissions();
    if (error) {
      throw Error(error);
    } else {
      return data;
    }
  }
);

// Obtener requests
export const getRequests = createAsyncThunk(
  `${PREFIX}/requests`,
  async (params: paramsOrdsRequest) => {
    const { error, data } = await GetRequests(params);
    if (error) {
      throw Error(ordsHandlerErrors(params));
    } else {
      return data;
    }
  }
);

// Obtener requests
export const getHolidays = createAsyncThunk(`${PREFIX}/holidays`, async () => {
  const { error, data } = await GetHolidays();
  if (error) {
    throw Error("Holidays");
  } else {
    return data;
  }
});

// Crear request
export const newRequest = createAsyncThunk(
  `${PREFIX}/new-request`,
  async (params: INewRequest) => {
    const { error, data } = await NewRequests(params);
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Crear permiso
export const newPermit = createAsyncThunk(
  `${PREFIX}/new-permit`,
  async (params: INewPermit & { onSuccess?: () => void }) => {
    const { error, data } = await NewPermit(params);
    if (error) {
      throw Error(error.message);
    } else {
      params.onSuccess && params.onSuccess();
      return data;
    }
  }
);

// Solcitar vacaciones
export const newVacation = createAsyncThunk(
  `${PREFIX}/new-vacation`,
  async (params: INewVacation & { onSuccess?: () => void }) => {
    const { error, data } = await NewVacation(params);
    if (error) {
      throw Error(error.message);
    } else {
      params.onSuccess && params.onSuccess();
      return data;
    }
  }
);

// Calcular días hábiles
export const calculateWorkingDays = createAsyncThunk(
  `${PREFIX}/calculate-working-days`,
  async (params: { date_ini: Date | string; date_fin: Date | string }) => {
    const { error, data } = await CalculateWorkingDays(params);
    if (error) {
      throw Error(error.message);
    } else {
      return data;
    }
  }
);

// Solcitar vacaciones
export const getDays = createAsyncThunk(`${PREFIX}/get-days`, async () => {
  const { error, data } = await GetDays();

  if (error) {
    throw Error(error.message);
  } else {
    return data;
  }
});

const ordsAdapter = createEntityAdapter<OrdsState>({});

export const ordsSlice = createSlice({
  name: PREFIX,
  initialState: ordsAdapter.getInitialState(initialState),
  reducers: {
    clearServerStatus(state) {
      state.loading = false;
      state.success = false;
      state.serverErrors = false;
    },
    clearCalculateWorkingDays(state) {
      state.calculateWorkingDays.loading = false;
      state.calculateWorkingDays.workingDays = undefined;
      state.calculateWorkingDays.message = undefined;
      state.calculateWorkingDays.code = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getContracts.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(getContracts.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.contracts.data = action.payload[0]?.items;
      state.contracts.count = action.payload[0]?.count || 0;
    });
    builder.addCase(getContracts.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getContractDetail.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(getContractDetail.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.contractDetail = action.payload.contractdetail;
    });
    builder.addCase(getContractDetail.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(
      getSalaryStructureDetail.pending,
      (state, action: IAction) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getSalaryStructureDetail.fulfilled,
      (state, action: IAction) => {
        state.loading = false;
        state.salaryStructureDetail = action.payload.salarystructuredetail;
      }
    );
    builder.addCase(
      getSalaryStructureDetail.rejected,
      (state, action: IAction) => {
        state.loading = false;
      }
    );
    builder.addCase(getCertificates.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(getCertificates.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.certificates.workSeniority = action.payload.workseniority;
      state.certificates.rent = action.payload.rent;
      state.certificates.user = action.payload.user;
    });
    builder.addCase(getCertificates.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getDiscounts.pending, (state, action: IAction) => {
      state.loading = true;
      state.discounts.data = initialState.discounts.data;
    });
    builder.addCase(getDiscounts.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.discounts.data = addPeriodity(action.payload[0]?.items);
      state.discounts.count = action.payload[1]?.count;
    });
    builder.addCase(getDiscounts.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getPayments.pending, (state, action: IAction) => {
      state.loading = true;
      state.payments.data = initialState.payments.data;
    });
    builder.addCase(getPayments.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.payments.data = addID(action.payload[0]?.items);
      state.payments.count = action.payload[0]?.count || 0;
    });
    builder.addCase(getPayments.rejected, (state, action: IAction) => {
      state.loading = false;
      state.errorMessage = action.error?.message;
    });
    builder.addCase(getAssignations.pending, (state, action: IAction) => {
      state.loading = true;
      state.assignations.data = initialState.assignations.data;
    });
    builder.addCase(getAssignations.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.assignations.data = addPeriodity(action.payload[0]?.items);
      state.assignations.count = action.payload[1]?.count;
    });
    builder.addCase(getAssignations.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getRequests.pending, (state, action: IAction) => {
      state.loading = true;
      state.requests.data = initialState.requests.data;
    });
    builder.addCase(getRequests.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.requests.data = addPeriodity(action.payload[0]?.items);
      state.requests.count = action.payload[1]?.count;
    });
    builder.addCase(getRequests.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getPermissions.pending, (state, action: IAction) => {
      state.loading = true;
      state.permissions = initialState.permissions;
    });
    builder.addCase(getPermissions.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.permissions.p_disponibles = action.payload.p_disponibles;
      state.permissions.permissionsRequests =
        action.payload.permissionsRequests;
      state.permissions.permissionsRegistereds =
        action.payload.permissionsRegistereds;
    });
    builder.addCase(getPermissions.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(getHolidays.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(getHolidays.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.holidays.p_acumulados = action.payload.p_acumulados;
      state.holidays.p_antiguedad = action.payload.p_antiguedad;
      state.holidays.p_disponibles = action.payload.p_disponibles;
      state.holidays.p_interrumpidos = action.payload.p_interrumpidos;
      state.holidays.p_periodo = action.payload.p_periodo;
      state.holidays.p_progresivo = action.payload.p_progresivo;
      state.holidays.p_tomados_periodo = action.payload.p_tomados_periodo;
      state.holidays.p_transferidos = action.payload.p_transferidos;
      state.holidays.p_zona = action.payload.p_zona;
      state.holidays.holidaysRequests = action.payload.holidaysRequests;
      state.holidays.holidaysRegistereds =
        action.payload.holidaysRegistereds ?? [];
    });
    builder.addCase(getHolidays.rejected, (state, action: IAction) => {
      state.loading = false;
    });
    builder.addCase(newRequest.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(newRequest.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(newRequest.rejected, (state, action: IAction) => {
      state.loading = false;
      state.serverErrors = true;
    });
    builder.addCase(newPermit.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(newPermit.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(newPermit.rejected, (state, action: IAction) => {
      state.loading = false;
      state.serverErrors = true;
    });
    builder.addCase(newVacation.pending, (state, action: IAction) => {
      state.loading = true;
    });
    builder.addCase(newVacation.fulfilled, (state, action: IAction) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(newVacation.rejected, (state, action: IAction) => {
      state.loading = false;
      state.serverErrors = true;
    });
    builder.addCase(calculateWorkingDays.pending, (state, action: IAction) => {
      state.calculateWorkingDays.loading = true;
    });
    builder.addCase(
      calculateWorkingDays.fulfilled,
      (state, action: IAction) => {
        state.calculateWorkingDays.loading = false;
        console.log(action.payload);
        state.calculateWorkingDays.workingDays = action.payload.p_cantidad;
        state.calculateWorkingDays.message = action.payload.p_det_error;
        state.calculateWorkingDays.code = action.payload.p_cod_error;
        state.calculateWorkingDays.success = true;
      }
    );
    builder.addCase(calculateWorkingDays.rejected, (state, action: IAction) => {
      state.calculateWorkingDays.loading = false;
      state.calculateWorkingDays.workingDays = undefined;
      state.calculateWorkingDays.message = undefined;
      state.calculateWorkingDays.serverErrors = true;
    });
  },
});

// Actions
export const { clearServerStatus, clearCalculateWorkingDays } =
  ordsSlice.actions;

// Reducer
export default ordsSlice.reducer;

// Utils

const addPeriodity = (ordsData: any[]) =>
  ordsData.slice(0, 20).map((item: any) => {
    return {
      periodo: `${dayjs(item.fecha_inicio).format("DD/MM/YYYY")} - ${dayjs(
        item.fecha_termino
      ).format("DD/MM/YYYY")}`,
      ...item,
    };
  });

const addID = (payments: any[]) =>
  payments.map((item: any) => {
    return {
      id: `${item.rol_emp} ${item.fecha_liquidacion} ${item.tipo_liquidacion}`,
      ...item,
    };
  });

const ordsHandlerErrors = ({
  toDate,
  status,
  fromDate,
  year,
}: paramsOrdsRequest) => {
  if (fromDate || status || toDate || year) {
    return "No hay registro que coincida con los parametros";
  } else {
    return "Aún no existen registros ingresados";
  }
};
