// Vendors
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// Components
import { TitleSection } from "components/pdf/TitleSection";
import { coinFormatterCLP } from "utils/text";

const styles = StyleSheet.create({
  root: {
    padding: "22px 0px",
    border: "1px solid #1C1C1C",
    borderRadius: 4,
  },
  boxMainInfo: {
    padding: "0px 45px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  info: {
    display: "flex",
    gap: "22px",
    width: "100%",
  },
  border: { border: "1px solid #1C1C1C", margin: "20px 0" },
  list: {
    display: "flex",
    gap: "22px",
  },
  titleList: {
    color: "#1E1E1E",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: 15,
  },
  itemList: {
    color: "#1E1E1E",
    textTransform: "uppercase",
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 9999,
    backgroundColor: "black",
    marginRight: 7.5,
  },
  monthColumn: {
    width: 180,
  },
});

interface IProps {
  data: any;
}

export const Body: React.FC<IProps> = ({ data }) => {
  const rentHeader = data.rent && data.rent.length > 0 ? data.rent[0] : {};
  const rent = data.rent || [];

  return (
    <View style={styles.root}>
      <View style={styles.boxMainInfo}>
        <View style={styles.info}>
          <TitleSection title="rut empresa" styles={{ marginBottom: 22 }}>
            {rentHeader?.rut_empresa || "N/A"}
          </TitleSection>
          <TitleSection title="rut" styles={{ marginBottom: 22 }}>
            {data?.user?.rut || ""}
          </TitleSection>
        </View>
        <View style={styles.info}>
          <TitleSection title="razón social" styles={{ marginBottom: 22 }}>
            {rentHeader?.nombre_empresa || "N/A"}
          </TitleSection>
          <TitleSection title="trabajador" styles={{ marginBottom: 22 }}>
            {data?.user?.trabajador || ""}
          </TitleSection>
        </View>
      </View>

      <View style={styles.border} />

      {rent.map((rentItem: any, index: number) => (
        <View style={styles.boxMainInfo} key={index}>
          <View style={styles.monthColumn}>
            <TitleSection title="Mes" styles={{ marginBottom: 22 }}>
              {rentItem?.periodo || "N/A"}
            </TitleSection>
          </View>
          <TitleSection title="renta neta" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(rentItem?.renta_neta || 0)}
          </TitleSection>
          <TitleSection title="H. no imponibles" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(rentItem?.renta_exentas || 0)}
          </TitleSection>
          <TitleSection title="renta bruta" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(rentItem?.renta_bruta || 0)}
          </TitleSection>
        </View>
      ))}
    </View>
  );
};
